import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleMapsComponent } from './components/google-maps/google-maps.component';
import { GoogleMap, GoogleMapsModule } from '@angular/google-maps';
import { GeocodingService } from './services/geocoding.service';


@NgModule({
    declarations: [
        GoogleMapsComponent
    ],
    imports: [
        CommonModule,
        GoogleMapsModule
    ],
    providers: [
        GeocodingService,
        GoogleMap
    ],
    exports: [
        GoogleMapsModule,
        GoogleMapsComponent
    ]
})
export class LocationsModule {
}
