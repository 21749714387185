import { NgModule } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthConfig } from './configs/auth.config';
import { CommonModule } from '@angular/common';

import { AUTH_CONFIG_TOKEN } from './tokens';

import { AuthGuard } from './guards/auth.guard';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { UnauthorizedUserPageComponent } from './components/unauthorized-user-page/unauthorized-user-page.component';
import { UnauthorizedUserModalComponent } from './components/unauthorized-user-modal/unauthorized-user-modal.component';

const routes = [
    { path: 'login', component: LoginPageComponent },
    { path: 'unauthorized', component: UnauthorizedUserPageComponent }
]

export function createAuthConfig(): AuthConfig {
    return {
        apiUrl: environment.authConfig.apiUrl,
        clientId: environment.authConfig.clientId,
        region: environment.authConfig.region,
        userPoolId: environment.authConfig.userPoolId,
    };
}

@NgModule({
    declarations: [
        LoginPageComponent,
        UnauthorizedUserPageComponent,
        UnauthorizedUserModalComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        SharedModule
    ],
    providers: [
        AuthGuard,
        RouterModule,
        {
            provide: AUTH_CONFIG_TOKEN,
            useFactory: createAuthConfig,
        }
    ],
})
export class AuthModule { }
