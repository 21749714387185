import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, take, takeUntil, timer } from 'rxjs';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'app-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit, OnDestroy {
    private readonly SEND_CODE_AGAIN_TIMER = 30;

    public canSendCodeAgain: boolean = false;
    public timesResetTimer: number = 0;
    public emailAddress: string = '';
    public showCodeEntry: boolean = false;
    public loginCode: string | undefined;
    public loggingIn: boolean = false;
    public codeRetryCountdown: number = 0;
    public submittingCode: boolean = false;

    private resetTimer = new Subject<void>();
    private restartLoginFlow = new Subject<void>();
    private destroyed = new Subject<void>();

    constructor(
        private authService: AuthService
    ) { }

    public ngOnInit(): void {
        const state = history.state;

        if (state && state?.signedUp === true) {
            this._initiateLoginAfterSignUp()
        }

        this._subscribeToVerification();
    }

    public ngOnDestroy(): void {
        this.resetTimer.complete();
        this.restartLoginFlow.complete();
        this.destroyed.complete();
    }

    public login(): void {
        this.restartLoginFlow.next();
        this.loggingIn = true;

        this.authService.signIn(this.emailAddress.trim()).pipe(takeUntil(this.restartLoginFlow)).subscribe((userLoggedIn) => {
            if (userLoggedIn === undefined) {
                this.submittingCode = false;
            }

            else if (!userLoggedIn && userLoggedIn !== undefined) {
                this.reset();
            }
        });
    }

    public submitCode(): void {
        if (!this.loginCode) return;

        this.submittingCode = true;

        this.authService.sendAuthCode(this.loginCode);
    }

    public resendNewCode(): void {
        this.authService.cancelExistingSignInProcess();
        this.login();
        this.activateResendCodeTimer();
    }

    public reset(): void {
        this.loggingIn = false;
        this.showCodeEntry = false;
        this.submittingCode = false;
        this.loginCode = '';
        this.resetResendCodeTimer();
        this.timesResetTimer = 0;
        this.authService.stopWaitingVerification();
    }

    private activateResendCodeTimer() {
        this.resetResendCodeTimer();

        this.codeRetryCountdown = this.SEND_CODE_AGAIN_TIMER;

        timer(1000, 1000).pipe(
            takeUntil(this.resetTimer),
            takeUntil(this.destroyed),
            take(this.SEND_CODE_AGAIN_TIMER + 1)
        ).subscribe(() => {
            if (this.codeRetryCountdown === 0) {
                this.canSendCodeAgain = true;
            }
            else this.codeRetryCountdown--;

        });

        this.canSendCodeAgain = false;
    }

    private resetResendCodeTimer(): void {
        this.resetTimer.next();
        this.timesResetTimer++;
    }

    private _subscribeToVerification() {
        this.authService.waitingVerification.subscribe((waitingVerification) => {
            if (waitingVerification) {
                if (this.timesResetTimer === 0) 
                    this.activateResendCodeTimer();

                this.showCodeEntry = true;
            }
        });
    }

    private _initiateLoginAfterSignUp() {
        this.emailAddress = history.state.emailAddress;
        this.login();
    }
}
