<div *ngIf="control">
  <div class="d-flex">
    <input
      [ngClass]="{ 'no-border': !showBorder }"
      class="w-100"
      [type]="type"
      [formControl]="control"
      [placeholder]="label"
    />
    <app-icon class="ms-2" *ngIf="icon" [icon]="icon"></app-icon>
  </div>

  <app-field-error-message
    *ngIf="control.touched"
    [errors]="control.errors"
  ></app-field-error-message>
</div>
