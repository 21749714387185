import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { catchError, EMPTY } from 'rxjs';
import { API_URL_TOKEN } from 'src/app/shared/tokens';
import { LogEventRequest } from '../requests/log-event-request';

@Injectable()
export class AnalyticsService {

    constructor(
        private _http: HttpClient,
        @Inject(API_URL_TOKEN) private apiUrl: string
    ) { }

    public logEvent(request: LogEventRequest): void {
        const params = {
            name: request.eventName,
            eventType: request.eventName
        };

        this._http.post(
            `${this.apiUrl}/analytics/ticket`,
            params
        ).pipe(
            catchError((err) => {
                console.error(err);

                return EMPTY;
            })
        ).subscribe();
    }
}
