import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { catchError, map, Observable, Subject, of as observableOf, tap, switchMap, combineLatest, BehaviorSubject, filter, take } from 'rxjs';
import { GOOGLE_MAPS_API_URL, GOOGLE_API_KEY } from 'src/app/shared/tokens';
import { NearbySearchRequest } from '../requests/nearby-search.request';
import { NearbySearchResponse } from '../responses/nearby-search.response';

@Injectable({
    providedIn: 'root'
})
export class GooglePlacesService {
    private apiLoaded: boolean = false;
    private loading = new BehaviorSubject<boolean>(false);
    private placesService: google.maps.places.PlacesService | undefined;

    constructor(
        private http: HttpClient,
        @Inject(GOOGLE_MAPS_API_URL) private googleMapsAPIUrl: string,
        @Inject(GOOGLE_API_KEY) private googleAPIKey: string
    ) { }

    public loadGoogleAPI(): Observable<boolean> {
        return combineLatest([
            observableOf(this.apiLoaded),
            this.loading.pipe(take(1))
        ]).pipe(
            switchMap(([apiLoaded, loading]) => {
                if (!apiLoaded && !loading) {
                    this.loading.next(true);

                    return this.http.jsonp(`${this.googleMapsAPIUrl}?key=${this.googleAPIKey}&libraries=places`, 'callback').pipe(
                        map(() => true),
                        tap(() => {
                            this.apiLoaded = true;
                            this.loading.next(false);
                        }),
                        catchError((error) => {
                            console.error(error);

                            return observableOf(false);
                        })
                    );
                }

                else if (loading) {
                    return this.loading.asObservable().pipe(
                        filter((loading) => !loading),
                        map(() => true),
                        take(1),
                    );
                }

                else return observableOf(true);
            })
        );
    }

    public initializeService(map: google.maps.Map): void {
        this.placesService = new google.maps.places.PlacesService(map);
    }

    public nearbySearch(request: NearbySearchRequest): Observable<NearbySearchResponse | undefined> {
        let result = new Subject<NearbySearchResponse | undefined>();

        if (!this.placesService) {
            result.next(undefined);
        }

        else {
            this.placesService.nearbySearch(request, (places, status, pagination) => {
                result.next({
                    places: places ?? undefined,
                    status: status,
                    pagination: pagination ?? undefined
                });
            });
        }

        return result.asObservable().pipe(take(1));
    }

    public createMapMarkers(places: google.maps.places.PlaceResult[]): google.maps.MarkerOptions[] {
        return places.map((place) => this.getMarkerDataFromPlace(place));
    }

    private getMarkerDataFromPlace(place: google.maps.places.PlaceResult): google.maps.MarkerOptions {
        return {
            position: place.geometry?.location,
            icon: place.icon,
            title: place.name
        };
    }

    public roundDown(num: number): number {
        return Math.floor(num);
    }
}
