import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { catchError, Observable, of as observableOf, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AlertsModule } from './alerts/alerts.module';
import { AnalyticsModule } from './analytics/analytics.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { ApplicationIdInterceptor } from './auth/interceptors/application-id.interceptor';
import { AuthorizationInterceptor } from './auth/interceptors/authorization.interceptor';
import { AuthService } from './auth/services/auth.service';
import { LocationsModule } from './locations/locations.module';
import { NavbarModule } from './navbar/navbar.module';
import { SharedModule } from './shared/shared.module';
import { API_URL_TOKEN, APP_BASE_URL, APP_VERSION, GOOGLE_API_KEY, GOOGLE_MAPS_API_URL } from './shared/tokens';
import { UserModule } from './user/user.module';

export function startAuthService(
    authService: AuthService,
    router: Router
): () => Observable<boolean> {
    return () => authService.startAuth().pipe(
        catchError((err) => {
            console.error(`An error occurred while authenticating user - ${err}`);
            return observableOf(false);
        }),
        tap((loggedIn) => {
            if (!loggedIn) {
                router.navigate(['/login']);
            } else {
                authService.redirectUser();
            }

        })
    );
}

@NgModule({ 
    declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent], 
    imports: [
        AlertsModule,
        AnalyticsModule,
        AppRoutingModule,
        AuthModule,
        BrowserModule,
        LocationsModule,
        NavbarModule,
        SharedModule,
        UserModule
    ], 
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: startAuthService,
            deps: [AuthService, Router],
            multi: true,
        },
        {
            provide: APP_BASE_URL,
            useValue: environment.baseUrl,
        },
        {
            provide: API_URL_TOKEN,
            useValue: environment.apiUrl
        },
        {
            provide: APP_VERSION,
            useValue: environment.version
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApplicationIdInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthorizationInterceptor,
            multi: true
        },
        {
            provide: GOOGLE_MAPS_API_URL,
            useValue: environment.google.mapsAPIUrl
        },
        {
            provide: GOOGLE_API_KEY,
            useValue: environment.google.apiKey
        },
        {
            provide: 'Window',
            useValue: window
        },
        provideHttpClient(withInterceptorsFromDi(), withJsonpSupport()),
    ] 
})
export class AppModule { }
