import { Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Component({
    selector: 'app-field-error-message',
    templateUrl: './field-error-message.component.html',
    styleUrls: ['./field-error-message.component.scss']
})
export class FieldErrorMessageComponent {
    @Input() public fieldLabel!: string;
    @Input() errors: ValidationErrors | undefined | null;

    public get errorTypes(): string[] {
        if (!this.errors) {
            return [];
        }

        return Object.keys(this.errors);
    }

    public getErrorMessage(errorType: string): string {
        if (errorType === 'required') {
            return `${this.fieldLabel} is required.`;
        }

        return this.errors![errorType];
    }
}
