import { Injectable } from "@angular/core";
import * as moment from "moment";

@Injectable()
export class TimeService {
    public convertDateStringToEpochMilliseconds(date: string): number {
        const dateVal = moment(date);

        return dateVal.valueOf();
    }

    public convertEpochMillisecondsToDateString(epoch: number): string {
        const date = moment(epoch);
        const dateString = date.format("YYYY-MM-DDTHH:mm:ss");

        return dateString;
    }
}