import { Injectable } from "@angular/core";
import { map, Observable, from as observableFrom, of as observableOf, catchError } from "rxjs";
import { AlertsService } from "src/app/alerts/services/alerts.service";
import { AlertType } from "src/app/alerts/types/alert.type";
import { LocationAddress } from "../models/location-address";

@Injectable()
export class GeocodingService {
    constructor(
        private alertsService: AlertsService
    ) { }
    public geocodeLocation(location: google.maps.LatLngLiteral): Observable<LocationAddress | undefined> {
        const geocoder = new google.maps.Geocoder();

        return observableFrom(geocoder.geocode({ location: location })).pipe(
            map((response) => {
                const data = response.results[0];
                const address = this.getAddressFromResult(data);

                return address;
            }),
            catchError(() => {
                this.alertsService.createAlert({
                    alertType: AlertType.Error,
                    alertMessage: 'An error occurred while determining the address of the selected location. Please try again. If the error persists, please try again later.'
                });

                return observableOf(undefined);
            })
        );
    }

    private getAddressFromResult(locationData: google.maps.GeocoderResult): LocationAddress {
        let pointOfInterestName: string | undefined;
        let premiseName: string | undefined;
        let streetNumber: string | undefined;
        let street: string | undefined;
        let plusCode: string | undefined;
        let city: string | undefined;
        let state: string | undefined;
        let zip: string | undefined;

        locationData.address_components.forEach((component) => {
            const hasPrincipalAddressComponent = pointOfInterestName || premiseName || (streetNumber && street) || plusCode;

            if (hasPrincipalAddressComponent && city && state && zip) {
                return;
            }

            const types = component.types;

            if (!pointOfInterestName && types.includes('point_of_interest')) {
                pointOfInterestName = component.short_name;
            }

            if (!premiseName && types.includes('premise')) {
                premiseName = component.long_name;
            }

            if (!streetNumber && types.includes('street_number')) {
                streetNumber = component.short_name;
            }

            if (!street && types.includes('route')) {
                street = component.short_name;
            }

            if (!plusCode && types.includes('plus_code')) {
                plusCode = component.short_name;
            }

            if (!city && types.includes('locality') && types.includes('political')) {
                city = component.long_name;
            }

            if (!state && types.includes('administrative_area_level_1') && types.includes('political')) {
                state = component.short_name;
            }

            if (!zip && types.includes('postal_code')) {
                zip = component.short_name;
            }
        });

        const addressParts = [];
        if (pointOfInterestName) addressParts.push(pointOfInterestName);
        else if (premiseName) addressParts.push(premiseName);
        else if (streetNumber && street) {
            addressParts.push(streetNumber);
            addressParts.push(street);
        }
        else {
            addressParts.push(plusCode);
        }

        return {
            addressOne: addressParts.join(' '),
            city: city!,
            state: state!,
            zip: zip!
        };
    }

    public getCoordinatesFromString(location: string): google.maps.LatLngLiteral | undefined {
        const locationCoordinates = location.replace(/\(*\)*/g, '').split(',');

        if (locationCoordinates.length <= 0) return undefined;

        return {
            lat: +locationCoordinates[0],
            lng: +locationCoordinates[1]
        };
    }
}