import { Component, ComponentRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { AlertsService } from '../../services/alerts.service';
import { AlertComponent } from '../alert/alert.component';

@Component({
    selector: 'app-alert-bar',
    templateUrl: './alert-bar.component.html',
    styleUrls: ['./alert-bar.component.scss']
})
export class AlertBarComponent implements OnInit {
    @ViewChild('alertArea', { read: ViewContainerRef, static: true }) public alertArea: ViewContainerRef | undefined;

    public existingAlerts: ComponentRef<AlertComponent>[] = [];

    constructor(private alertsService: AlertsService) { }

    public ngOnInit(): void {
        if (this.alertArea) {
            this.alertsService.registerAlertBar(this.alertArea);
        }

        this.alertsService.existingAlerts.subscribe((alerts) => {
            this.existingAlerts = alerts;
        });
    }
}
