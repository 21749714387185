import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, switchMap, of as observableOf } from 'rxjs';
import { API_URL_TOKEN } from 'src/app/shared/tokens';
import { AuthConfig } from '../configs/auth.config';
import { AuthService } from '../services/auth.service';
import { AUTH_CONFIG_TOKEN } from '../tokens';

@Injectable({
    providedIn: 'root'
})
export class AuthorizationInterceptor implements HttpInterceptor {
    constructor(
        @Inject(API_URL_TOKEN) private apiUrl: string,
        @Inject(AUTH_CONFIG_TOKEN) private authConfig: AuthConfig,
        private authService: AuthService
    ) { }

    public intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (!(req.url.startsWith(this.apiUrl) || req.url.startsWith(this.authConfig.apiUrl))) {
            return next.handle(req);
        }

        return this.authService.isSessionValid().pipe(
            switchMap((sessionValid) => {
                if (!sessionValid) {
                    return this.authService.refreshSession();
                }

                return observableOf(true);
            }),
            switchMap(() => {
                return this.authService.getIdToken().pipe(
                    switchMap((token) => {
                        if (!token) {
                            console.warn(`[Authorization] - No ID Token found!`);

                            return next.handle(req);
                        }

                        else {
                            const newReq = req.clone({
                                headers: req.headers.set('Authorization', token!),
                            });

                            return next.handle(newReq);
                        }
                    }),
                );
            }),
        );
    }
}
