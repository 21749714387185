import { AfterViewInit, Component } from '@angular/core';
import { ModalService } from 'src/app/shared/modal/services/modal.service';
import { AuthService } from '../../services/auth.service';
import { UnauthorizedUserModalComponent } from '../unauthorized-user-modal/unauthorized-user-modal.component';

@Component({
    selector: 'app-unauthorized-user-page',
    templateUrl: './unauthorized-user-page.component.html',
    styleUrls: ['./unauthorized-user-page.component.scss']
})
export class UnauthorizedUserPageComponent implements AfterViewInit {
    constructor(
        private modalService: ModalService,
        private authService: AuthService
    ) { }

    public ngAfterViewInit(): void {
        this.openModal();
    }

    public openModal(): void {
        this.modalService.openModal(UnauthorizedUserModalComponent, {
        }).subscribe(() => {
            this.authService.logout();
        });
    }
}
