import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-text-field',
    templateUrl: './text-field.component.html',
    styleUrls: ['./text-field.component.scss']
})
export class TextFieldComponent {
    @Input() public control: FormControl | undefined;
    @Input() public label: string | undefined = '';
    @Input() public type: 'text' | 'email' | 'password' = 'text';
    @Input() public showBorder: boolean = true;
    @Input() public icon: string | undefined;
}
