import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/guards/auth.guard';

const routes: Routes = [
    {
        path: 'home', loadChildren: () => import('./home/home.module').then((m) => m.HomeModule), canActivate: [AuthGuard],
    },
    {
        path: 'events', loadChildren: () => import('./events/details/event-details.module').then((m) => m.EventDetailsModule), canActivate: [AuthGuard]
    },
    {
        path: '', redirectTo: 'home', pathMatch: 'full',
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
