import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextFieldComponent } from './components/text-field/text-field.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateTimeFieldComponent } from './components/date-time-field/date-time-field.component';
import { SharedIconsModule } from '../icons/shared-icons.module';
import { FieldErrorMessageComponent } from './components/field-error-message/field-error-message.component';

@NgModule({
    declarations: [
        DateTimeFieldComponent,
        FieldErrorMessageComponent,
        TextFieldComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedIconsModule
    ],
    exports: [
        DateTimeFieldComponent,
        FieldErrorMessageComponent,
        FormsModule,
        ReactiveFormsModule,
        TextFieldComponent
    ]
})
export class SharedFormsModule { }
