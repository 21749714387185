import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ModalService } from './shared/modal/services/modal.service';
import { User } from './user/models/user';
import { UserService } from './user/services/user.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
    @ViewChild('modalContainer', { read: ViewContainerRef }) private modalContainer: ViewContainerRef | undefined;

    public currentUser: User | undefined;

    constructor(
        private userService: UserService,
        private modalService: ModalService,
        private changeDetector: ChangeDetectorRef
    ) { }

    public ngOnInit(): void {
        this.userService.currentUser.subscribe((user) => {
            this.currentUser = user;
        });
    }

    public ngAfterViewInit(): void {
        if (this.modalContainer) {
            this.modalService.registerModalContainer(this.modalContainer);

            this.changeDetector.detectChanges();
        }
    }
}
