import { environment as sharedEnvironment } from './environment.shared';

export const environment = {
    production: false,
    apiUrl: 'https://eventtube.api.dev.v1.oneaaa.com',
    authConfig: {
        apiUrl: 'https://admin.api.dev.v1.oneaaa.com',
        clientId: '4d4ni3mjbho4fnt0ve65rbkdr7',
        region: 'us-east-1',
        userPoolId: 'us-east-1_MoC0HTkl7',
    },
    baseUrl: 'https://admin.eventtube.dev.oneaaa.com',
    google: {
        mapsAPIUrl: 'https://maps.googleapis.com/maps/api/js',
        apiKey: 'AIzaSyA-GnoFZT4E6W23I3zLKooXAzdQhAwfN1E',
    },

    ...sharedEnvironment
};
