<nav class="navbar navbar-dark navbar-expand-lg">
    <div class="container-fluid">
        <ng-container *ngTemplateOutlet="logo"></ng-container>
        <div class="d-flex">
            <button class="navbar-toggler sticky" type="button" data-bs-toggle="offcanvas"
                data-bs-target="#navbarDrawer" aria-controls="navbarDrawer">
                <span class="navbar-toggler-icon"></span>
            </button>
        </div>

        <div class="offcanvas offcanvas-end" tabindex="-1" id="navbarDrawer" aria-labelledby="navbarDrawerLabel">
            <div class="offcanvas-header">
                <ng-container *ngTemplateOutlet="logo"></ng-container>
                <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="row sticky">
                <div class="col d-flex justify-content-end">
                    <ng-container id="userAuth">
                        <button class="ms-2 btn btn-primary-alt-md"
                            (click)="logout()">Logout</button>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</nav>

<ng-template #logo>
    <a class="navbar-brand ms-md-5 my-2 sticky" routerLink="/home">
        <img src="assets/images/eventtube-logo.png">
    </a>
</ng-template>