<app-modal [lockBackground]="true">
    <ng-container header>
        <h5>Unauthorized Access</h5>
    </ng-container>
    <ng-container body>
        <div>You are not allowed to access this console. Please reach out to your EventTube admin.</div>
        <div class="mt-2">Once you have been given access, you will need to logout and log back in.</div>
    </ng-container>
    <ng-container footer>
        <button class="btn btn-danger w-100" (click)="closeModal(true)">
            <div>Logout</div>
        </button>
    </ng-container>
</app-modal>