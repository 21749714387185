import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-spinner-overlay',
    templateUrl: './spinner-overlay.component.html',
    styleUrls: ['./spinner-overlay.component.scss']
})
export class SpinnerOverlayComponent {
    @Input() public showProgress: boolean = false;
    @Input() public useLargeSpinner: boolean = false;
}
