<div class="d-flex flex-column align-items-center w-100 mx-auto text-center">
  <div class="header">Login</div>

  <div class="mt-4">
    Log in with your email! We use a password-less authentication system, where
    we will send you a 6-digit code.
  </div>

  <div class="mt-4 w-75 w-md-50 w-lg-50 w-xl-25" *ngIf="!showCodeEntry">
    <form>
      <div class="text-start text-muted fst-italic">Email</div>
      <input
        type="text"
        autofocus
        id="email"
        class="form-control"
        name="email"
        placeholder="Enter email..."
        required
        minlength="5"
        #email
        [(ngModel)]="emailAddress"
      />

      <app-spinner-overlay class="d-block mb-2 mt-4" [showProgress]="loggingIn">
        <button
          type="submit"
          [disabled]="loggingIn"
          class="btn btn-primary w-100 position-relative"
          (click)="login()"
        >
          <div [ngClass]="{ invisible: loggingIn }">Login</div>
        </button>
      </app-spinner-overlay>
    </form>
  </div>

  <div class="mt-4 w-75 w-md-50 w-lg-50 w-xl-25" *ngIf="showCodeEntry">
    <div class="my-4">Please check your inbox for a verification code.</div>
    <form>
      <div class="text-start text-muted fst-italic">Code</div>
      <div>
        <input
          type="text"
          id="loginCode"
          class="form-control"
          name="loginCode"
          placeholder="Enter code..."
          required
          minlength="5"
          #code
          [(ngModel)]="loginCode"
        />

        <app-spinner-overlay
          class="d-block my-3"
          [showProgress]="submittingCode"
        >
          <button
            type="submit"
            class="btn btn-primary w-100 position-relative"
            (click)="submitCode()"
            [disabled]="submittingCode"
          >
            <div [ngClass]="{ invisible: submittingCode }">Submit Code</div>
          </button>
        </app-spinner-overlay>

        <div class="mt-4 w-100">
          <button
            class="btn btn-primary w-100"
            [disabled]="submittingCode || !canSendCodeAgain"
            (click)="resendNewCode()"
          >
            <div class="d-flex justify-content-center">
              <div>Try Again</div>
              <div class="ms-1" *ngIf="!canSendCodeAgain">
                {{ "(" + codeRetryCountdown.toString() + ")" }}
              </div>
            </div>
          </button>
        </div>
        <button class="btn mt-3" (click)="reset()" [disabled]="submittingCode">
          Back
        </button>
      </div>
    </form>
  </div>
</div>
