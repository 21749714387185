import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { timer } from 'rxjs';
import { GooglePlacesService } from '../../services/google-places.service';

@Component({
    selector: 'app-google-maps',
    templateUrl: './google-maps.component.html',
    styleUrls: ['./google-maps.component.scss']
})
export class GoogleMapsComponent implements AfterViewInit {
    @ViewChild('mapSearchField', { read: ElementRef }) private searchBarElement: ElementRef | undefined;

    @Input() public enabled: boolean = true;

    @Input() public set coordinates(coordinates: google.maps.LatLngLiteral | undefined) {
        this._coordinates = coordinates;

        if (this.mapLoaded && this._coordinates) {
            this.drawCursorMarker(this._coordinates);
        }
    }
    public get coordinates(): google.maps.LatLngLiteral | undefined {
        return this._coordinates;
    }
    private _coordinates: google.maps.LatLngLiteral | undefined;

    @Input() public markers: google.maps.MarkerOptions[] = [];
    @Input() public enableSearch: boolean = false;

    @Output() public mapInitialized = new EventEmitter<google.maps.Map>();
    @Output() public positionChanged = new EventEmitter<google.maps.LatLngLiteral>();

    public readonly options: google.maps.MapOptions = {
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false
    };

    public apiLoaded: boolean = false;
    public mapLoaded: boolean = false;

    public cursorMarker: google.maps.MarkerOptions | undefined;

    private map: google.maps.Map | undefined;

    constructor(
        private googlePlacesService: GooglePlacesService,
        private changeDetector: ChangeDetectorRef
    ) { }

    public ngAfterViewInit(): void {
        this.googlePlacesService.loadGoogleAPI().subscribe((isLoaded) => {
            this.apiLoaded = isLoaded;

            this.changeDetector.detectChanges();
        });
    }

    public mapCreated(map: google.maps.Map): void {
        this.map = map;

        if (this.searchBarElement) {
            const searchBox = new google.maps.places.SearchBox(
                this.searchBarElement.nativeElement
            );

            this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(
                this.searchBarElement.nativeElement
            );

            searchBox.addListener('places_changed', () => {
                if (!this.enabled) {
                    return;
                }

                const places = searchBox.getPlaces();

                if (places?.length === 0) return;

                const bounds = new google.maps.LatLngBounds();

                places?.forEach((place) => {
                    if (!place.geometry || !place.geometry.location) {
                        return;
                    }

                    if (place.geometry.viewport) {
                        bounds.union(place.geometry.viewport);
                    } else {
                        bounds.extend(place.geometry.location);
                    }
                });

                this.map?.fitBounds(bounds);

                if (places && places[0] && places[0].geometry && places[0].geometry?.location) {
                    const location = places[0].geometry?.location;

                    this.drawCursorMarker(location.toJSON());
                    this.updatePosition(location.toJSON());
                }
            });
        }

        this.mapLoaded = map !== undefined;
        this.mapInitialized.emit(map);

        if (this.coordinates) {
            this.drawCursorMarker(this.coordinates);
        }

        this.changeDetector.detectChanges();
    }

    public mapClick(click: google.maps.MapMouseEvent | google.maps.IconMouseEvent): void {
        if (click.latLng && this.enabled) {
            const location = click.latLng.toJSON();
            this.drawCursorMarker(location);
            this.updatePosition(location);
        }
    }

    private drawCursorMarker(location: google.maps.LatLngLiteral): void {
        this.cursorMarker = {
            position: location
        };

        // 2023-02-17: Without a timer, the google maps component will not load the marker when we navigate away and come back.
        timer(1).subscribe(() => {
            this.map?.panTo(location);
        });
    }

    public resetMap(): void {
        this.cursorMarker = undefined;

        if (this.coordinates) {
            this.drawCursorMarker(this.coordinates);
        }
    }

    private updatePosition(location: google.maps.LatLngLiteral): void {
        this.positionChanged.emit(location);

        this.changeDetector.detectChanges();
    }
}
